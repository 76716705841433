import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getData } from "../services/authService"; // ✅ Import API call

const ProtectedRoute = ({ element }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isExpired, setIsExpired] = useState(false);

    useEffect(() => {
        const checkPlanStatus = async () => {
            try {
                const res = await getData("/last-plan-status"); // ✅ Call API
                if (res.status === "Expired") {
                    setIsExpired(true);
                }
            } catch (error) {
                console.error("Error checking plan:", error);
            } finally {
                setIsLoading(false);
            }
        };

        checkPlanStatus();
    }, []);

    if (isLoading) return <div>Loading...</div>; // Prevents flickering

    if (isExpired) {
        return <Navigate to="/upgradeac" replace />;
    }

    return element;
};

export default ProtectedRoute;

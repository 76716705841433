const API_URL = 'https://admin.smoochy.uk/api'; // Replace with your API URL

// Utility function to get headers
const getHeaders = (isAuthRequired = false) => {
    const headers = {
        'Content-Type': 'application/json',
    };

    if (isAuthRequired) {
        const token = localStorage.getItem('authToken');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
    }

    return headers;
};

// Handle fetch errors and responses
const handleResponse = async (response) => {
    let responseData;
  
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      try {
        responseData = await response.json();
      } catch (error) {
        console.error("Error parsing response JSON:", error);
        responseData = {};
      }
    } else {
      responseData = { rawText: await response.text() };
    }
  
    if (!response.ok) {
      return { status: "error", ...responseData, httpStatus: response.status };
    }
  
    return responseData;
  };
  



// API Methods

const login = async (email, password) => {
    const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ email, password }),
    });

    const data = await handleResponse(response);

    if (data.token) {
        localStorage.setItem('authToken', data.token);
    }

    return data;
};

const register = async (payload) => {
    const response = await fetch(`${API_URL}/register`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    });

    const data = await handleResponse(response);

    if (data.token) {
        localStorage.setItem('authToken', data.token);
    }

    return data;
};

const forget = async (email) => {
    const response = await fetch(`${API_URL}/forget-Password`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ email }),
    });

    return await handleResponse(response);
};

const contact = async (name, email, subject, message) => {
    const response = await fetch(`${API_URL}/contact`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ name, email, subject, message }),
    });

    return await handleResponse(response);
};

const postData = async (route, payload, isAuthRequired = true) => {
    const response = await fetch(`${API_URL}${route}`, {
        method: 'POST',
        headers: getHeaders(isAuthRequired),
        body: JSON.stringify(payload),
    });

    return await handleResponse(response);
};

const getData = async (route, isAuthRequired = true) => {
    const response = await fetch(`${API_URL}${route}`, {
        method: 'GET',
        headers: getHeaders(isAuthRequired),
    });

    return await handleResponse(response);
};

const initiateIDScan = async (email) => {
    const response = await fetch(`${API_URL}/agechecked-id-scan`, {
        method: 'POST',
        headers: getHeaders(true),
        body: JSON.stringify({
            email,
            callbackUrl: `${API_URL}/id-scan-callback`,
        }),
    });

    return await handleResponse(response);
};

const checkIDScanStatus = async (agecheckid) => {
    const response = await fetch(`${API_URL}/id-scan-status`, {
        method: 'POST',
        headers: getHeaders(true),
        body: JSON.stringify({ agecheckid }),
    });

    return await handleResponse(response);
};

const checkPlanExpiration = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) return;

    try {
        const response = await fetch(`${API_URL}/last-plan-status`, {
            method: 'GET',
            headers: getHeaders(true),
        });

        const data = await handleResponse(response);
        console.log("Plan Status:", data);

        if (data.status === "Expired") {
            window.location.href = "/upgradeac"; // 🔴 Redirect to Upgrade Page
        }
    } catch (error) {
        console.error("Error checking plan status:", error);
    }
};

// Export functions
export {
    login,
    register,
    forget,
    contact,
    postData,
    getData,
    initiateIDScan,
    checkIDScanStatus,
    checkPlanExpiration
};

import React, { useEffect, useState } from "react";

import TopHeader from "../../components/TopHeader";
import BottomTabs from "../../components/BottomTabs";

import { AiOutlineStop, AiOutlineCheckCircle } from "react-icons/ai";

import { getData, postData } from "../../services/authService";
import LoaderService from "../../services/loader";
import { showToast } from '../../services/toastService';

const BlockedUsers = () => {
  const [token, setToken] = useState(localStorage.getItem("userToken") || null);
  const [blockedUsersList, setBlockedUsersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleBlockedUsersList_Data();
  }, []);

  const handleBlockedUsersList_Data = () => {
    setIsLoading(true);
    getData("/blocked-user", token)
      .then((res) => {
        console.log(res);
        if (res.status === "success") {
          setIsLoading(false);
          setBlockedUsersList(res.data);
        } else if (res.error) {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleUnblock = (userId) => {
    setIsLoading(true);
    postData("/unblock-user", { id: userId }, token)
      .then((res) => {
        if (res.status === "success") {
          // Remove unblocked user from the list
          setBlockedUsersList((prevList) =>
            prevList.filter((user) => user.id !== userId)
          );
          setIsLoading(false);
          showToast("User has been unblocked.");
        }
      })
      .catch((error) => console.error("Unblock failed:", error));
  };

  return (
    <div className="app">
      <TopHeader />

      <main className="content-main">
        <div className="dashboard-content" style={{ flexDirection: "column" }}>
          <div style={{ width: "90%", margin: "0px auto", position: "relative" }}>
            <div className="title-div" style={{ textAlign: "center", marginTop: 30, marginBottom: 20 }}>
              Blocked Users
            </div>

            {blockedUsersList.length > 0 ? (
              <>
                {blockedUsersList.map((user) => (
                  <div key={user.id} className="userList-Div">
                    <div className="userAvatar-Div">
                      <img src={user.image} alt="User Avatar" />
                    </div>
                    <div style={{ width: 165, marginLeft: 5 }}>
                      <h4 style={{ color: "#000" }}>{user.name}</h4>
                    </div>
                    <div>
                      {/* <AiOutlineStop style={{ color: "#ff1547", fontSize: 40, marginRight: 2 }} /> */}
                    </div>
                    <button
                      style={{
                        backgroundColor: "#4CAF50", // Green for Unblock
                        color: "white",
                        border: "none",
                        padding: "10px 20px",
                        borderRadius: "30px",
                        cursor: "pointer",
                        fontSize: "15px",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px"
                      }}
                      onClick={() => handleUnblock(user.id)}
                    >
                                <AiOutlineCheckCircle style={{ fontSize: 15 }} />
                                Unblock</button>
                    <div
                      onClick={() => handleUnblock(user.id)}
                    >
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="no-data-msg" style={{ marginTop: "100%" }}>
                No Blocked user data found!
              </div>
            )}
          </div>
        </div>
      </main>

      <BottomTabs />
      {isLoading && <LoaderService />}
    </div>
  );
};

export default BlockedUsers;
